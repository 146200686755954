import KBArticlesListTable from './KBArticlesListTable/KBArticlesListTable';
import './KBArticlesList.css';

const KBArticlesList = () => {
	return (
		<div className="container-fluid kb-articles-list-container">
			<div className="container">
				<div className="row">
					<div className="col-12 text-center">
						<h1 className="mb-4">Articles</h1>

						<div className="row">
							<div className="col-lg-12 col-sm-12 text-center">
								<KBArticlesListTable />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default KBArticlesList;
