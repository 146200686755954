import { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useStateValue } from '../../../../StateProvider';
import { SubmitNewTicketEmail } from '../../../../TicketsService';
import CenteredContainer from '../../../centeredContainer/CenteredContainer';
import Spinner from '../../../spinner/Spinner';
import './SubmitTicketForm.css';

const SubmitNewTicketForm = () => {
	const [ { brand }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(false);
	const [ formData, setFormData ] = useState({});
	const history = useHistory();

	useEffect(() => {
		let email = new URLSearchParams(window.location.search).get('email');

		if (email) {
			//prepopulate email field if exists in URL
			let tempFormData = {
				FromAddress : email
			};

			setFormData(tempFormData);
		}
	}, []);

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;
		//const checked = e.target.checked;

		// if (checked !== undefined) {
		// 	setFormData({
		// 		...formData,
		// 		[target] : checked
		// 	});
		// }
		setFormData({
			...formData,
			[target] : value
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (brand && brand.settings.smarterTrackDepartmentId) {
			setIsLoading(true);

			let data = {
				...formData,
				DepartmentId     : brand.settings.smarterTrackDepartmentId,
				IsHtml           : true,
				SetAutoResponder : false
			};

			const response = await SubmitNewTicketEmail(data);

			if (response.IsSuccessful) {
				history.push('/submit-thank-you');
			} else {
				console.log('Email not sent.');
			}

			setIsLoading(false);
		}
	};

	return (
		<Fragment>
			{!isLoading ? (
				<form onSubmit={handleSubmit}>
					<div className="form-group mt-3">
						<input
							onChange={handleChange}
							value={formData.FullName}
							id="txtFullName"
							name="FullName"
							type="text"
							className="form-control form-control-lg"
							placeholder="Your Name"
							required
							disabled={isLoading}
						/>
					</div>
					<div className="form-group mt-3">
						<input
							onChange={handleChange}
							value={formData.FromAddress}
							id="txtEmail"
							name="FromAddress"
							type="email"
							className="form-control form-control-lg"
							placeholder="Email Address"
							required
							disabled={isLoading}
						/>
					</div>
					<div className="form-group mt-3">
						<input
							onChange={handleChange}
							value={formData.Subject}
							id="txtSubject"
							name="Subject"
							type="text"
							className="form-control form-control-lg"
							placeholder="Subject"
							required
							disabled={isLoading}
						/>
					</div>
					<div className="form-group mt-3">
						<textarea
							onChange={handleChange}
							value={formData.Body}
							id="txtMessage"
							name="Body"
							className="form-control form-control-lg"
							placeholder="Message"
							rows="5"
							disabled={isLoading}
						/>
					</div>
					<div className="form-group mt-3 text-center">
						<button type="submit" className="btn btn-primary btn-lg" disabled={isLoading}>
							{isLoading ? (
								<Fragment>
									<Spinner />
									<span>Submitting...</span>
								</Fragment>
							) : (
								<Fragment>
									<span>Submit</span>
								</Fragment>
							)}
						</button>
					</div>
				</form>
			) : (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			)}
		</Fragment>
	);
};

export default SubmitNewTicketForm;
