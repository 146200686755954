import { Link } from 'react-router-dom';
import TicketsListTable from './ticketsListTable/TicketsListTable';
import './TicketsList.css';

const TicketsList = () => {
	return (
		<div className="container-fluid tickets-list-container">
			<div className="container">
				<div className="row">
					<div className="col-12 text-center">
						<h1 className="mb-4">Your Support Tickets</h1>

						<div className="row">
							<div className="col-lg-12 col-sm-12 text-center">
								<TicketsListTable />
							</div>
						</div>
						<div className="row">
							<div className="col-lg-12 text-start">
								<Link to="/submit-ticket" className="btn btn-primary btn-lg">
									<span>New Ticket</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TicketsList;
