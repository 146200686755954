import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GetKBArticles } from '../../../../KBService';
import Spinner from '../../../spinner/Spinner';
import NoDataMessage from '../../../noDataMessage/NoDataMessage';
import Moment from 'react-moment';
import './KBArticlesListTable.css';

const KBArticlesListTable = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ KBArticlesList, setKBArticlesList ] = useState([]);
	const [ searchTerm, setSearchTerm ] = useState('');

	useEffect(
		() => {
			getKBArticles();
		},
		[ searchTerm ]
	);

	const getKBArticles = async () => {
		let data = {};
		data.Limit = 10;

		const response = await GetKBArticles(data);

		if (response.IsSuccessful) {
			let tempKBArticlesList = JSON.parse(response.Data);

			if (searchTerm !== undefined && searchTerm !== null && searchTerm !== '') {
				tempKBArticlesList = tempKBArticlesList.filter((item) => {
					return (
						item.Subject.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
						item.Body.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0 ||
						item.KBCategoryName.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
					);
				});
			}

			setKBArticlesList(tempKBArticlesList);
		} else {
			toast.error('Error: ' + response.Message);
		}

		setIsLoading(false);
	};

	const onSearchChange = (e) => {
		setSearchTerm(e.target.value);
	};

	const onSearchKeyDown = (e) => {
		if (e.keyCode === 13) {
			setSearchTerm(e.target.value);
		}
	};

	return (
		<Fragment>
			{isLoading ? (
				<div className="text-center">
					<Spinner />
				</div>
			) : (
				<div className="container-fluid kb-articles-table-container px-0 py-4">
					<div className="container px-0">
						<div className="row pb-3">
							<div className="col-lg-9" />
							<div className="col-lg-3 text-end">
								<span className="input-group-append">
									<button
										type="button"
										className="btn btn-outline-secondary border-start-0 border btn-input-search"
										onClick={getKBArticles}
										title="Search Articles"
									>
										<i className="fa fa-search me-0" />
									</button>
								</span>
								<input
									type="search"
									className="form-control border"
									onChange={onSearchChange}
									onKeyDown={onSearchKeyDown}
									value={searchTerm}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12 text-center">
								<Fragment>
									{KBArticlesList && KBArticlesList.length > 0 ? (
										<Fragment>
											<div className="kb-articles-list">
												<div className="table-kb-articles-list rw-table">
													<div className="rw-table-header">
														<div className="rw-th title">Title</div>
														<div className="rw-th date">Date</div>
														<div className="rw-th action" />
													</div>

													<div className="rw-table-data">
														{/* KB Articles list */}
														{KBArticlesList.map((article) => (
															<div
																className="rw-row"
																key={article.KBArticleID.toString()}
															>
																<div className="rw-td title">
																	<span>{article.Subject}</span>
																</div>
																<div className="rw-td date">
																	<Moment format="MM/DD/YYYY">
																		{article.DateCreatedUTC}
																	</Moment>
																</div>
																<div className="rw-td action">
																	<Link
																		to={`/kb-article/${article.KBArticleID}`}
																		className="btn btn-primary"
																	>
																		View Article
																	</Link>
																</div>
															</div>
														))}
													</div>
												</div>
											</div>
										</Fragment>
									) : (
										<NoDataMessage message="No articles found" />
									)}
								</Fragment>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default KBArticlesListTable;
