import { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Header from '../header/Header';
import WelcomePage from '../welcomePage/WelcomePage';
import SubmitNewTicket from '../tickets/submitTicket/SubmitTicket';
import SubmitThankYou from '../tickets/submitTicket/submitTicketThankYou/SubmitTicketThankYou';
import ViewTickets from '../tickets/viewTickets/ViewTickets';
import CheckEmail from '../checkEmail/CheckEmail';
import TicketsList from '../tickets/ticketsList/TicketsList';
import TicketDetails from '../tickets/ticketsList/ticketDetails/TicketDetails';
import ScheduleCall from '../scheduleCall/ScheduleCall';
import KBArticlesList from '../KBArticles/KBArticlesList/KBArticlesList';
import KBArticleDetails from '../KBArticles/KBArticleDetails/KBArticleDetails';
import Page404 from '../404/Page404';
import './MainApp.css';

const MainApp = () => {
	return (
		<Fragment>
			<div className="main-app">
				<div className="main-app-container">
					<Router>
						<Header />
						<ScheduleCall />

						<Switch>
							<Route path="/tickets-list">
								<TicketsList />
							</Route>
							<Route path="/submit-ticket">
								<SubmitNewTicket />
							</Route>
							<Route path="/submit-thank-you">
								<SubmitThankYou />
							</Route>
							<Route path="/view-tickets">
								<ViewTickets />
							</Route>
							<Route path="/check-email">
								<CheckEmail />
							</Route>
							<Route path="/ticket/:ticketNumber">
								<TicketDetails />
							</Route>
							<Route path="/kb-article/:KBArticleId">
								<KBArticleDetails />
							</Route>
							<Route path="/kb-articles">
								<KBArticlesList />
							</Route>
							<Route path="/" exact>
								<WelcomePage />
							</Route>
							<Route path="*">
								<Page404 />
							</Route>
						</Switch>
					</Router>
				</div>
			</div>
		</Fragment>
	);
};

export default MainApp;
