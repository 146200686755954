import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useStateValue } from '../../StateProvider';
import './Header.css';

const Header = () => {
	const [ { brand }, dispatch ] = useStateValue();

	const handleChatOpen = () => {
		if (window.LC_API) {
			//setChatDetails();

			setTimeout(
				() => {
					window.LC_API.open_chat_window();
				},
				[ 100 ]
			);
		}
	};

	return (
		<div className="container-fluid header">
			<div className="row">
				<div className="col-4 text-start">
					<Link to="/">
						{brand && (
							<Fragment>
								<img src={brand.settings.logo} alt="" />
							</Fragment>
						)}
					</Link>
				</div>
				<div className="col-8 text-end">
					<ul className="header-nav">
						<li>
							<Link to="/submit-ticket" className="btn btn-primary-link" href="">
								Submit Ticket
							</Link>|
						</li>
						<li>
							<Link to="/view-tickets" className="btn btn-primary-link">
								View Tickets
							</Link>
						</li>
						<li>
							<a className="btn btn-outline-primary" onClick={handleChatOpen}>
								<span>Chat</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default Header;
