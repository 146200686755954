import './CheckEmail.css';

const CheckEmail = () => {
	const handleChatOpen = () => {
		if (window.LC_API) {
			setTimeout(
				() => {
					window.LC_API.open_chat_window();
				},
				[ 100 ]
			);
		}
	};

	return (
		<div className="container-fluid check-email">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 text-center">
						<h1 className="mb-2">Check Your Email!</h1>
						<h5 className="mb-4">
							If you have not received your tickets via email and still need help
							<a href="javacript:void(0)" onClick={handleChatOpen} className="px-2">
								click here
							</a>
							for live chat support.
						</h5>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CheckEmail;
