import SubmitNewTicketForm from './submitTicketForm/SubmitTicketForm';
import './SubmitTicket.css';

const SubmitNewTicket = () => {
	return (
		<div className="container-fluid submit-ticket-container">
			<div className="container">
				<div className="row">
					<div className="col-12 text-center">
						<h1 className="mb-2">Submit A New Support Ticket</h1>
						<h5 className="mb-5">
							Please submit the form below and we will respond within 24 hours. You'll be notified by
							email when we reply.
						</h5>

						<div className="row">
							<div className="col-lg-6 offset-lg-3 col-sm-12 text-center">
								<SubmitNewTicketForm />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubmitNewTicket;
