import axios from 'axios';

//const apiHost = 'http://localhost:55700/' + '/Services/';
const apiHost = window.location.origin + '/Services/';

export const SubmitNewTicketEmail = async (data) => {
	try {
		const response = await axios.post(apiHost + 'TicketsService.asmx/CreateTicket', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		});

		return response.data.d;
	} catch (error) {
		const response = {};
		response.IsSuccessful = false;
		response.Message = error.message;

		console.log(error);

		return response;
	}
};

export const SubmitViewTicketsEmail = async (data) => {
	try {
		const response = await axios.post(apiHost + 'TicketsService.asmx/GetTicketsByEmail', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		});

		return response.data.d;
	} catch (error) {
		const response = {};
		response.IsSuccessful = false;
		response.Message = error.message;

		console.log(error);

		return response;
	}
};

export const ViewTickets = async (data) => {
	try {
		const response = await axios.post(apiHost + 'TicketsService.asmx/GetTicketsByEmail', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		});

		return response.data.d;
	} catch (error) {
		const response = {};
		response.IsSuccessful = false;
		response.Message = error.message;

		console.log(error);

		return response;
	}
};

export const GetTicketDetails = async (data) => {
	try {
		const response = await axios.post(apiHost + 'TicketsService.asmx/GetTicketDetails', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		});

		return response.data.d;
	} catch (error) {
		const response = {};
		response.IsSuccessful = false;
		response.Message = error.message;

		console.log(error);

		return response;
	}
};

export const CloseTicket = async (data) => {
	try {
		const response = await axios.post(apiHost + 'TicketsService.asmx/CloseTicket', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		});

		return response.data.d;
	} catch (error) {
		const response = {};
		response.IsSuccessful = false;
		response.Message = error.message;

		console.log(error);

		return response;
	}
};

export const ReOpenTicket = async (data) => {
	try {
		const response = await axios.post(apiHost + 'TicketsService.asmx/ReOpenTicket', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		});

		return response.data.d;
	} catch (error) {
		const response = {};
		response.IsSuccessful = false;
		response.Message = error.message;

		console.log(error);

		return response;
	}
};

export const CreateTicketReply = async (data) => {
	try {
		const response = await axios.post(apiHost + 'TicketsService.asmx/CreateTicketReply', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		});

		return response.data.d;
	} catch (error) {
		const response = {};
		response.IsSuccessful = false;
		response.Message = error.message;

		console.log(error);

		return response;
	}
};

export const SendEmailToCustomer = async (data) => {
	try {
		const response = await axios.post(apiHost + 'TicketsService.asmx/SendEmailToCustomer', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		});

		return response.data.d;
	} catch (error) {
		const response = {};
		response.IsSuccessful = false;
		response.Message = error.message;

		console.log(error);

		return response;
	}
};

export default SubmitNewTicketEmail;
