import { Fragment, useEffect } from 'react';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';
import config from '../../config.json';

const BrandLoader = () => {
	const [ { brand }, dispatch ] = useStateValue();

	useEffect(
		() => {
			let reloadBrandData = false;

			// load the localStorage brand data if available
			let tempBrand = localStorage.getItem('supportAppBrand');

			if (tempBrand) {
				tempBrand = JSON.parse(tempBrand);

				if (tempBrand.domain !== window.location.host) {
					reloadBrandData = true;
				}
			} else {
				reloadBrandData = true;
			}

			if (reloadBrandData) {
				for (let i = 0; i < config.domains.length; i++) {
					let domainItem = config.domains[i];

					if (domainItem.domain === window.location.host) {
						let storageData = domainItem;

						localStorage.setItem('supportAppBrand', JSON.stringify(storageData));

						addBrandInformation(storageData);

						dispatch({
							type  : actionTypes.SET_BRAND,
							brand : storageData
						});

						break;
					}
				}
			} else {
				addBrandInformation(tempBrand);

				dispatch({
					type  : actionTypes.SET_BRAND,
					brand : tempBrand
				});
			}
		},
		[ dispatch ]
	);

	const addBrandInformation = (brand) => {
		if (brand.settings.favicon && brand.settings.favicon !== '') {
			const favicon = document.getElementById('favicon');
			favicon.href = brand.settings.favicon;
		}

		document.title = brand.settings.brandName;
	};

	return <Fragment />;
};

export default BrandLoader;
