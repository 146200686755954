import { Link } from 'react-router-dom';
import './SubmitTicketThankYou.css';

const SubmitThankYou = () => {
	return (
		<div className="container-fluid submit-thank-you">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 text-center">
						<h1 className="mb-2">Thank You!</h1>
						<h5 className="mb-4">
							We received your request and we will respond within 24 hours. You'll be notified by email
							when we reply.
						</h5>

						<div className="row">
							<div className="col-lg-12">
								<Link to="/view-tickets" className="btn btn-link">
									View Your Tickets
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SubmitThankYou;
