import { useStateValue } from '../../StateProvider';
import { Fragment, useEffect, useState } from 'react';

const SessionLoader = ({ setSessionLoaded }) => {
	const [ { brand }, dispatch ] = useStateValue();
	const [ chatLoaded, setChatLoaded ] = useState(false);

	useEffect(
		() => {
			handleLocationChange();
		},
		[ brand ]
	);

	useEffect(() => {
		handleLCGroup();
	}, []);

	const handleLCGroup = () => {
		if (!chatLoaded) {
			// load LC script
			if (brand) {
				window.__lc = window.__lc || {};
				window.__lc.license = 10943002;

				window.__lc.group = brand.settings.liveChatGroupId;

				var lc = document.createElement('script');
				lc.type = 'text/javascript';
				lc.async = true;
				lc.src =
					('https:' === document.location.protocol ? 'https://' : 'http://') +
					'cdn.livechatinc.com/tracking.js';
				var s = document.getElementsByTagName('script')[0];
				s.parentNode.insertBefore(lc, s);

				setChatLoaded(true);
			}
		}
	};

	const handleLocationChange = async () => {
		if (brand) {
			setSessionLoaded(true);
		} else {
			setSessionLoaded(true);
		}
	};

	return <Fragment />;
};

export default SessionLoader;
