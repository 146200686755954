export const initialState = {
	brand            : null,
	viewTicketsEmail : null,
	showScheduleCall : false
};

export const actionTypes = {
	VIEW_TICKETS_EMAIL     : 'VIEW_TICKETS_EMAIL',
	SET_BRAND              : 'SET_BRAND',
	SET_SHOW_SCHEDULE_CALL : 'SET_SHOW_SCHEDULE_CALL'
};

const reducer = (state, action) => {
	//console.log(action);
	switch (action.type) {
		case actionTypes.SET_BRAND:
			return {
				...state,
				brand : action.brand
			};
		case actionTypes.VIEW_TICKETS_EMAIL:
			return {
				...state,
				viewTicketsEmail : action.viewTicketsEmail
			};
		case actionTypes.SET_SHOW_SCHEDULE_CALL:
			return {
				...state,
				showScheduleCall : action.showScheduleCall
			};
		default:
			return state;
	}
};

export default reducer;
