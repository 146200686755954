import { Fragment, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { GetKBArticleDetails } from '../../../KBService';
import CenteredContainer from '../../centeredContainer/CenteredContainer';
import Spinner from '../../spinner/Spinner';
import Moment from 'react-moment';
import './KBArticleDetails.css';

const KBArticleDetails = () => {
	const [ KBArticle, setKBArticle ] = useState(null);
	const [ isLoading, setIsLoading ] = useState(true);
	const match = useRouteMatch();
	const history = useHistory();
	const KBArticleId = match.params.KBArticleId;

	useEffect(
		() => {
			getKBArticleDetails();
		},
		[ KBArticleId ]
	);

	const getKBArticleDetails = async () => {
		let data = {};
		data.ArticleId = KBArticleId;

		const response = await GetKBArticleDetails(data);

		if (response.IsSuccessful) {
			setKBArticle(JSON.parse(response.Data));

			setIsLoading(false);
		}
	};

	const backToKBArticlesList = () => {
		history.push('/kb-articles');
	};

	return (
		<div className="container-fluid kb-article-details-container">
			{isLoading ? (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			) : (
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="row mb-4">
								<div className="col-lg-8 offset-lg-2 col-sm-12">
									<button className="btn btn-primary" onClick={backToKBArticlesList}>
										<i className="fas fa-chevron-left" />
										<span>Back To Articles List</span>
									</button>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-8 offset-lg-2 col-sm-12">
									<Fragment>
										{KBArticle && (
											<Fragment>
												{KBArticle.Subject && (
													<Fragment>
														<h4 className="mb-2">{KBArticle.Subject}</h4>
														{/* <div className="mb-5">
															Submitted on{' '}
															<span className="">
																<Moment format="MMMM DD, YYYY @ h:mmA">
																	{KBArticle.DateCreatedUTC}
																</Moment>{' '}
																EST
															</span>
														</div> */}
														<div className="mb-4">
															<span>{`Category: ${KBArticle.KBCategoryName}`}</span>
														</div>
														<div className="mb-4">
															<div
																className="kb-article-reply-body p-3 my-2 border"
																dangerouslySetInnerHTML={{
																	__html : KBArticle.Body
																}}
															/>
														</div>
													</Fragment>
												)}
											</Fragment>
										)}
									</Fragment>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default KBArticleDetails;
