import { useEffect, useState } from 'react';
import { useStateValue } from '../../../StateProvider';
import { SendEmailToCustomer } from '../../../TicketsService';
import { actionTypes } from '../../../reducer';
import { useHistory } from 'react-router-dom';
import './ViewTickets.css';

const ViewTickets = () => {
	const [ { brand, viewTicketsEmail }, dispatch ] = useStateValue();
	const [ email, setEmail ] = useState('');
	const history = useHistory();

	const handleChange = (e) => {
		const target = e.target.name;

		if (target === 'txtEmail') {
			setEmail(e.target.value);
		}
	};

	useEffect(() => {
		let email = new URLSearchParams(window.location.search).get('email');

		if (email) {
			//get tickets list if email exists in URL
			history.push('/tickets-list');
		} else if (localStorage.getItem('supportAppEmail')) {
			const data = JSON.parse(localStorage.getItem('supportAppEmail'));

			if (data.email && data.brandId && data.brandId === brand.settings.smarterTrackBrandId) {
				history.push('/tickets-list');
			} else {
				//localStorage.removeItem('supportAppEmail');
			}
		}
	}, []);

	const handleSubmit = async (e) => {
		e.preventDefault();

		dispatch({
			type             : actionTypes.VIEW_TICKETS_EMAIL,
			viewTicketsEmail : email
		});

		//add email to localStorage
		localStorage.setItem(
			'supportAppEmail',
			JSON.stringify({
				email   : email,
				brandId : brand.settings.smarterTrackBrandId
			})
		);

		//send email to customer
		let data = {
			BrandName         : brand.settings.brandName,
			BrandSupportEmail : brand.settings.brandSupportEmail,
			BrandUrl          : brand.settings.brandUrl,
			BrandLogo         : brand.settings.logo,
			PrimaryColor      : brand.settings.primaryColor,
			CustomerEmail     : email,
			TicketsListUrl    : window.location.origin + '/tickets-list'
		};

		const response = await SendEmailToCustomer(data);

		if (response.IsSuccessful) {
			history.push('/check-email');
		}
	};

	return (
		<div className="container-fluid view-tickets">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 text-center">
						<h1 className="mb-2">View Your Support Tickets</h1>
						<h5 className="mb-4">
							Please enter any email address used to submit a ticket and click the submit button to have
							your tickets emailed to you.
						</h5>

						<form onSubmit={handleSubmit}>
							<div className="row py-4">
								<div className="col-lg-6 offset-lg-3 col-sm-12 text-center">
									<input
										onChange={handleChange}
										value={email}
										id="txtEmail"
										name="txtEmail"
										type="email"
										className="form-control form-control-lg"
										placeholder="Email Address"
										required
									/>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-12">
									<button type="submit" className="btn btn-primary btn-lg">
										Submit
									</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewTickets;
