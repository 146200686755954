import './App.css';
import { Fragment, useState, useEffect } from 'react';
import { useStateValue } from './StateProvider';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import MainApp from './components/mainApp/MainApp';
import BrandLoader from './components/brandLoader/BrandLoader';
import SessionLoader from './components/sessionLoader/SessionLoader';
import Spinner from './components/spinner/Spinner';
import CenteredContainer from './components/centeredContainer/CenteredContainer';

function App() {
	const [ { brand }, dispatch ] = useStateValue();
	const [ isLoading, setIsLoading ] = useState(true);
	const [ sessionLoaded, setSessionLoaded ] = useState(false);
	const [ toastifyPosition, setToastifyPosition ] = useState('bottom-left');

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		handleResize();

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(
		() => {
			if (brand && sessionLoaded) {
				setIsLoading(false);

				if (brand.settings.primaryColor) {
					document.documentElement.style.setProperty('--primary-color', brand.settings.primaryColor);
				}
			}
		},
		[ brand, sessionLoaded ]
	);

	useEffect(
		() => {
			if (brand && sessionLoaded) {
			}
		},
		[ brand, sessionLoaded ]
	);

	const handleResize = () => {
		const windowW = window.innerWidth;

		if (windowW <= 767) {
			setToastifyPosition('top-center');
		} else {
			setToastifyPosition('bottom-left');
		}
	};

	return (
		<div className="App">
			<Fragment>
				<BrandLoader />
				{brand && (
					<Fragment>
						<SessionLoader setSessionLoaded={setSessionLoaded} />
					</Fragment>
				)}
				{isLoading ? (
					<CenteredContainer>
						<Spinner />
					</CenteredContainer>
				) : (
					<MainApp />
				)}
				<ToastContainer
					position={toastifyPosition}
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop={false}
					closeOnClick
				/>
			</Fragment>
		</div>
	);
}

export default App;
