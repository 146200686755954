import axios from 'axios';

//const apiHost = 'http://localhost:55700/Services/';
const apiHost = window.location.origin + '/Services/';

export const GetKBArticles = async (data) => {
	try {
		const response = await axios.post(apiHost + 'KBService.asmx/GetArticles', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		});

		return response.data.d;
	} catch (error) {
		const response = {};
		response.IsSuccessful = false;
		response.Message = error.message;

		console.log(error);

		return response;
	}
};

export const GetKBArticleDetails = async (data) => {
	try {
		const response = await axios.post(apiHost + 'KBService.asmx/GetArticleDetails', data, {
			headers : {
				'Content-Type' : 'application/json'
			}
		});

		return response.data.d;
	} catch (error) {
		const response = {};
		response.IsSuccessful = false;
		response.Message = error.message;

		console.log(error);

		return response;
	}
};
