import { Fragment, useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { GetTicketDetails } from '../../../../TicketsService';
import CloseTicketDialog from './closeTicketDialog/CloseTicketDialog';
import TicketReply from '../../submitReply/SubmitReply';
import CenteredContainer from '../../../centeredContainer/CenteredContainer';
import Spinner from '../../../spinner/Spinner';
import Moment from 'react-moment';
import './TicketDetails.css';

const TicketDetails = () => {
	const [ ticket, setTicket ] = useState(null);
	const [ isLoading, setIsLoading ] = useState(true);
	const [ closeTicketDialog, setCloseTicketDialog ] = useState(false);
	const [ showHideSubmitReply, setShowHideSubmitReply ] = useState(null);
	const match = useRouteMatch();
	const history = useHistory();
	const ticketNumber = match.params.ticketNumber;

	useEffect(() => {
		getTicketDetails();
	}, []);

	const getTicketDetails = async () => {
		let data = {};
		data.TicketNumber = ticketNumber;

		const response = await GetTicketDetails(data);

		if (response.IsSuccessful) {
			setTicket(JSON.parse(response.Data));

			setIsLoading(false);
		}
	};

	const handleReplyState = (sender) => {
		if (sender) {
			let $reply = sender.currentTarget.closest('.ticket-reply');

			if ($reply.classList.contains('expanded')) {
				$reply.classList.remove('expanded');
				$reply.querySelectorAll('.ticket-reply-button i')[0].classList.remove('fa-caret-square-up');
				$reply.querySelectorAll('.ticket-reply-button i')[0].classList.add('fa-caret-square-down');
			} else {
				$reply.classList.add('expanded');
				$reply.querySelectorAll('.ticket-reply-button i')[0].classList.remove('fa-caret-square-down');
				$reply.querySelectorAll('.ticket-reply-button i')[0].classList.add('fa-caret-square-up');
			}
		}
	};

	const showCloseTicketDialog = () => {
		setCloseTicketDialog(true);
	};

	const hideCloseTicketDialog = () => {
		setCloseTicketDialog(false);
	};

	const showSubmitReplyArea = () => {
		setShowHideSubmitReply(true);
	};

	const hideSubmitReplyArea = () => {
		setShowHideSubmitReply(false);
	};

	const backToTicketsList = () => {
		history.push('/tickets-list');
	};

	return (
		<div className="container-fluid ticket-details-container">
			{isLoading ? (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			) : (
				<div className="container">
					<div className="row">
						<div className="col-12">
							<div className="row mb-4">
								<div className="col-lg-8 offset-lg-2 col-sm-12">
									<a href="#" className="btn btn-outline-primary" onClick={backToTicketsList}>
										<i class="fas fa-chevron-left" />
										Back To Tickets List
									</a>
								</div>
							</div>
							<div className="row">
								<div className="col-lg-8 offset-lg-2 col-sm-12">
									<Fragment>
										{ticket && (
											<Fragment>
												{ticket.Subject && (
													<Fragment>
														{ticket.IsOpen ? (
															<span className="ticket-status active">ACTIVE</span>
														) : (
															<span className="ticket-status closed">CLOSED</span>
														)}
														<h1 className="mb-4 text-center">Ticket Details</h1>
														<h4 className="mb-2">{ticket.Subject}</h4>
														<div className="mb-4">
															<span className="">{ticket.TicketNumber}</span> - Submitted
															on{' '}
															<span className="">
																<Moment format="MMMM DD, YYYY @ h:mmA">
																	{ticket.DateReceived}
																</Moment>{' '}
																EST
															</span>
														</div>
														<div className="mb-4">
															{!showHideSubmitReply && (
																<a
																	className="btn btn-primary btn-lg mr-3"
																	onClick={showSubmitReplyArea}
																>
																	<span>Reply</span>
																</a>
															)}
															{!showHideSubmitReply &&
															ticket.IsOpen && (
																<Fragment>
																	<a
																		className="btn btn-primary btn-lg"
																		onClick={showCloseTicketDialog}
																	>
																		<span>Close</span>
																	</a>
																</Fragment>
															)}
														</div>

														{showHideSubmitReply && (
															<TicketReply
																ticket={ticket}
																handleSubmitReplyState={hideSubmitReplyArea}
																handleSubmitReply={getTicketDetails}
															/>
														)}

														{ticket.TicketParts && ticket.TicketParts.length > 0 ? (
															<Fragment>
																<div className="ticket-replies">
																	{ticket.TicketParts.map((ticketPart, i) => (
																		<Fragment>
																			<div
																				key={ticketPart.PartId.toString()}
																				className={
																					i === 0 ? (
																						'ticket-reply mb-3 expanded'
																					) : (
																						'ticket-reply mb-3'
																					)
																				}
																			>
																				<div className="ticket-reply-title p-2 mb-3">
																					<a
																						className="ticket-reply-button"
																						onClick={handleReplyState}
																					>
																						<i className="far fa-caret-square-down" />
																					</a>
																					<span>{ticketPart.Sender}</span>
																					<span> - </span>
																					<span>
																						<Moment format="MMMM DD, YYYY @ h:mmA">{ticketPart.CreationDate}</Moment>{' '}
																						EST
																					</span>
																				</div>
																				<div
																					className="ticket-reply-body p-2 my-2"
																					dangerouslySetInnerHTML={{
																						__html : ticketPart.Message
																					}}
																				/>
																			</div>
																		</Fragment>
																	))}
																</div>
															</Fragment>
														) : (
															<div className="spacer" />
														)}
													</Fragment>
												)}
											</Fragment>
										)}

										{closeTicketDialog && (
											<CloseTicketDialog
												//ticket={selectedWebsite}
												closeDialog={hideCloseTicketDialog}
												callback={getTicketDetails}
											/>
										)}
									</Fragment>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default TicketDetails;
