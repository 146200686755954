import { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useStateValue } from '../../../../StateProvider';
import { useHistory } from 'react-router-dom';
import { actionTypes } from '../../../../reducer';
import { toast } from 'react-toastify';
import axios from 'axios';
import { SubmitViewTicketsEmail } from '../../../../TicketsService';
import Spinner from '../../../spinner/Spinner';
import NoDataMessage from '../../../noDataMessage/NoDataMessage';
import './TicketsListTable.css';

const TicketsListTable = () => {
	const [ isLoading, setIsLoading ] = useState(true);
	const [ email, setEmail ] = useState('');
	const [ ticketsList, setTicketsList ] = useState([]);
	const [ { brand, viewTicketsEmail }, dispatch ] = useStateValue();
	const history = useHistory();

	useEffect(
		() => {
			if (email) {
				getTickets();
			}
		},
		[ email ]
	);

	useEffect(() => {
		let token = new URLSearchParams(window.location.search).get('token');
		let email = new URLSearchParams(window.location.search).get('email');

		if (token) {
			//send request to datapost page to validate the token
			if (brand.settings.validateUrl) {
				axios
					.get(brand.settings.validateUrl + '/?token=' + token)
					.then(function(response) {
						// success

						if (response.data.indexOf('ERROR') > -1) {
							//let error = response.data.replace('ERROR: ', '');
							toast.error('Error: could not validate the token. ');
						} else {
							setEmail(response.data);

							//add email to localStorage
							localStorage.setItem(
								'supportAppEmail',
								JSON.stringify({
									email   : response.data,
									brandId : brand.settings.smarterTrackBrandId
								})
							);
						}
					})
					.catch(function(error) {
						// error
						toast.error('Error: could not validate the token. Reason: ' + error);
					})
					.then(function() {
						// always executed
					});
			}
		} else if (email) {
			//get tickets list if email exists in URL
			setEmail(email);
		} else if (localStorage.getItem('supportAppEmail')) {
			// load the email from localStorage if available
			const data = JSON.parse(localStorage.getItem('supportAppEmail'));

			if (data.email && data.brandId && data.brandId === brand.settings.smarterTrackBrandId) {
				setEmail(data.email);

				dispatch({
					type             : actionTypes.VIEW_TICKETS_EMAIL,
					viewTicketsEmail : null
				});
			} else {
				localStorage.removeItem('supportAppEmail');
				history.push('/view-tickets');
			}
		} else {
			history.push('/view-tickets');
		}
	}, []);

	const getTickets = async () => {
		let data = {};
		data.EmailAddress = email;
		data.BrandId = brand.settings.smarterTrackBrandId;

		const response = await SubmitViewTicketsEmail(data);

		if (response.IsSuccessful) {
			let tempTicketsList = JSON.parse(response.Data);

			setTicketsList(tempTicketsList);
		} else {
			toast.error('Error: ' + response.Message);
		}

		setIsLoading(false);
	};

	return (
		<Fragment>
			{isLoading ? (
				<div className="text-center">
					<Spinner />
				</div>
			) : (
				<div className="container-fluid tickets-table-container px-0 py-4">
					<div className="container px-0">
						<div className="row">
							<div className="col-12 text-center">
								<Fragment>
									{ticketsList && ticketsList.length > 0 ? (
										<Fragment>
											<div className="tickets-list">
												<div className="table-tickets-list rw-table">
													<div className="rw-table-header">
														<div className="rw-th last-reply">Last Reply</div>
														<div className="rw-th number">Ticket Number</div>
														<div className="rw-th subject">Subject</div>
														<div className="rw-th status">Status</div>
														<div className="rw-th action" />
													</div>

													{/* tickets list */}
													{ticketsList.map((ticket) => (
														<div className="rw-row" key={ticket.ID.toString()}>
															<div className="rw-td last-reply">
																<span>{ticket.LastReplyDateStr}</span>
															</div>
															<div className="rw-td number">
																<span>{ticket.TicketNumber}</span>
															</div>
															<div className="rw-td subject">
																<span>{ticket.Subject}</span>
															</div>
															<div className="rw-td status">
																<span>{ticket.Status}</span>
															</div>
															<div className="rw-td action">
																<Link
																	to={`/ticket/${ticket.TicketNumber}`}
																	className="btn btn-primary"
																>
																	View Ticket
																</Link>
															</div>
														</div>
													))}
												</div>
											</div>
										</Fragment>
									) : (
										<NoDataMessage message="No tickets found" />
									)}
								</Fragment>
							</div>
						</div>
					</div>
				</div>
			)}
		</Fragment>
	);
};

export default TicketsListTable;
