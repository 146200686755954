import { useEffect, useState } from 'react';
import './ModalDialogContent.css';

const ModalDialogContent = ({ children, align }) => {
	const [ alignment, setAlignment ] = useState('left');

	useEffect(
		() => {
			if (align) {
				setAlignment(align);
			}
		},
		[ align ]
	);

	return <div className={`modal-dialog-content text-${alignment}`}>{children}</div>;
};

export default ModalDialogContent;
