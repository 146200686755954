import { Fragment, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStateValue } from '../../StateProvider';
import { actionTypes } from '../../reducer';
import './WelcomePage.css';

const WelcomePage = () => {
	const [ { brand, showScheduleCall }, dispatch ] = useStateValue();

	useEffect(
		() => {
			if (brand) {
				enableNichebuilderLiveChat();
			}
		},
		[ brand ]
	);

	const handleChatOpen = () => {
		if (window.LC_API) {
			//setChatDetails();

			setTimeout(
				() => {
					window.LC_API.open_chat_window();
				},
				[ 100 ]
			);
		}
	};

	const handleScheduleCall = (e) => {
		e.stopPropagation();
		toggleScheduleCallPanel();
	};

	const toggleScheduleCallPanel = () => {
		dispatch({
			type             : actionTypes.SET_SHOW_SCHEDULE_CALL,
			showScheduleCall : true
		});
	};

	const enableNichebuilderLiveChat = () => {
		if (brand && brand.settings.brandName === 'NicheBuilder') {
			//add scripts in head tag
			var script1 = document.createElement('script');
			script1.type = 'text/javascript';
			script1.src = 'https://portal.nichebuilder.com/JavaScript.ashx?fileMask=Optional/ChatScripting';
			script1.defer = 'defer';
			document.head.appendChild(script1);
		}
	};

	const stLiveChatButtonClick = () => {
		let topPos = window.screen.height - 630;
		let leftPos = window.screen.width - 550;
		let newWindow = window.open(
			'https://portal.nichebuilder.com/Chat/frmClientPreChat.aspx?config=1',
			'LiveChat1',
			'width=550,height=630,top=' + topPos + ',left=' + leftPos
		);
		if (newWindow) {
			newWindow.focus();
			newWindow.opener = window;
		}
		return false;
	};

	return (
		<div className="container-fluid content">
			<div className="container">
				<div className="row">
					<div className="col-12 text-center">
						{brand && brand.settings.brandName ? (
							<Fragment>
								<h1 className="mb-2">Welcome to {brand.settings.brandName} Support</h1>
							</Fragment>
						) : (
							<Fragment>
								<h1 className="mb-2">Welcome to Support</h1>
							</Fragment>
						)}
						<h5 className="mb-5">How Can We Help You?</h5>

						<div className="row justify-content-center">
							{brand && brand.settings.brandName === 'NicheBuilder' ? (
								<Fragment>
									<div className="col-lg-auto col-md-auto col-sm-12">
										<div className="welcome-support-type">
											<img
												style={{
													position   : 'absolute',
													bottom     : '0',
													right      : '0',
													zIndex     : -9999,
													visibility : 'hidden'
												}}
												id="detectControlID"
												src="https://portal.nichebuilder.com/ChatScript.ashx?config=1&refresh=1"
											/>
											<a
												id="aControlIDon"
												className="submit-button submit-button-link online"
												title="Live Chat by SmarterTrack"
												onClick={stLiveChatButtonClick}
											>
												<i className="far fa-comment-alt" />
											</a>
											<a
												style={{ display: 'none' }}
												id="aControlIDoff"
												href="https://portal.nichebuilder.com/Main/frmNewTicket.aspx"
												target="_blank"
												title="No agents are currently available.  Click to submit a Ticket."
												className="submit-button submit-button-link disabled"
											>
												Submit A Support Ticket
											</a>
											<script type="text/javascript">
												checkRefreshTime("https://portal.nichebuilder.com/",'ControlID','1',0);
											</script>
										</div>
										<span>Live Chat</span>
									</div>
								</Fragment>
							) : brand && brand.settings.liveChatGroupId ? (
								<Fragment>
									<div className="col-lg-auto col-md-auto col-sm-12">
										<div className="welcome-support-type">
											<a onClick={handleChatOpen}>
												<i className="far fa-comment-alt" />
											</a>
										</div>
										<span>Live Chat</span>
									</div>
								</Fragment>
							) : (
								''
							)}
							{brand &&
							brand.settings.smarterTrackDepartmentId && (
								<Fragment>
									<div className="col-lg-auto col-md-auto col-sm-12">
										<div className="welcome-support-type">
											<Link to="/submit-ticket">
												<i className="far fa-calendar-check" />
											</Link>
										</div>
										<span>Submit Ticket</span>
									</div>
								</Fragment>
							)}
							{brand &&
							brand.settings.scheduleCallUrl && (
								<Fragment>
									<div className="col-lg-auto col-md-auto col-sm-12">
										<div className="welcome-support-type">
											<a onClick={handleScheduleCall}>
												<i className="fas fa-phone-alt" />
											</a>
										</div>
										<span>Talk To Us</span>
									</div>
								</Fragment>
							)}
							{brand &&
							brand.settings.knowledgeBaseEnabled &&
							brand.settings.knowledgeBaseEnabled === true && (
								<Fragment>
									<div className="col-lg-auto col-md-auto col-sm-12">
										<div className="welcome-support-type">
											<Link to="/kb-articles">
												<i className="fas fa-book-open" />
											</Link>
										</div>
										<span>Knowledge Base</span>
									</div>
								</Fragment>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default WelcomePage;
