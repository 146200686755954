import { Fragment, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import ModalDialog from '../../../../modalDialog/ModalDialog';
import ModalDialogButtons from '../../../../modalDialog/modalDialogButtons/ModalDialogButtons';
import ModalDialogContent from '../../../../modalDialog/modalDialogContent/ModalDialogContent';
import { CloseTicket } from '../../../../../TicketsService';
import Spinner from '../../../../spinner/Spinner';
import './CloseTicketDialog.css';

const CloseTicketDialog = ({ closeDialog, callback }) => {
	const [ isSaving, setIsSaving ] = useState(false);
	const match = useRouteMatch();
	const ticketNumber = match.params.ticketNumber;

	const closeTicketSubmit = async (e) => {
		e.preventDefault();

		setIsSaving(true);

		let data = {};
		data.TicketNumber = ticketNumber;

		const response = await CloseTicket(data);

		if (response.IsSuccessful) {
			//toast.success(response.Message);

			if (callback) {
				callback();
			}

			closeDialog();
		} else {
			//toast.error(response.Message);
		}

		setIsSaving(false);
	};

	return (
		<Fragment>
			<ModalDialog>
				<ModalDialogContent align="center">
					<h2 className="mb-4">Close This Ticket?</h2>
					<p className="text-start">
						Please confirm that you would like to close this ticket. You can always create a new ticket if
						you still have questions or need additional support.
					</p>
				</ModalDialogContent>
				<ModalDialogButtons align="center" shaded={true}>
					<button
						type="button"
						className="btn btn-primary btn-lg mr-4"
						disabled={isSaving}
						onClick={closeTicketSubmit}
					>
						{!isSaving ? (
							<Fragment>
								<span>Yes</span>
							</Fragment>
						) : (
							<Fragment>
								<Spinner />
								<span>Closing...</span>
							</Fragment>
						)}
					</button>
					<button
						type="button"
						className="btn btn-outline-primary btn-lg"
						disabled={isSaving}
						onClick={closeDialog}
					>
						<span>No</span>
					</button>
				</ModalDialogButtons>
			</ModalDialog>
		</Fragment>
	);
};

export default CloseTicketDialog;
