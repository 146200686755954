import { useState } from 'react';
import { CreateTicketReply } from '../../../TicketsService';
import { toast } from 'react-toastify';
import { ReOpenTicket } from '../../../TicketsService';
import CenteredContainer from '../../centeredContainer/CenteredContainer';
import Spinner from '../../spinner/Spinner';
import './SubmitReply.css';

const TicketReply = ({ ticket, handleSubmitReplyState, handleSubmitReply }) => {
	const [ isLoading, setIsLoading ] = useState(false);
	const [ formData, setFormData ] = useState({});

	const handleChange = (e) => {
		const target = e.target.name;
		const value = e.target.value;

		setFormData({
			...formData,
			[target] : value
		});
	};

	const submitReply = async (e) => {
		e.preventDefault();

		if (formData.Body && formData.Body !== '') {
			if (handleSubmitReplyState && handleSubmitReply) {
				setIsLoading(true);

				let data = {
					...formData,
					TicketNumber : ticket.TicketNumber,
					FromAddress  : ticket.FromAddress,
					ToAddress    : ticket.ToAddress,
					CCAddress    : ticket.CCAddress,
					Subject      : ticket.Subject,
					IsHtml       : true,
					ToCustomer   : ticket.ToCustomer,
					SendEmail    : false
				};

				const response = await CreateTicketReply(data);

				if (response.IsSuccessful) {
					//if ticket is closed, reopen it
					if (!ticket.IsOpen) {
						data = {};
						data.TicketNumber = ticket.TicketNumber;

						const response1 = await ReOpenTicket(data);

						if (response1.IsSuccessful) {
							//setTicket(JSON.parse(response1.Data));
						}
					}

					handleSubmitReplyState();
					handleSubmitReply();

					toast.success('Ticket reply successfully created!');

					setIsLoading(false);
				} else {
					toast.error('Error: could not create ticket reply. Reason: ' + response.Message);
					setIsLoading(false);
				}

				setIsLoading(false);
			}
		}
	};

	const cancelReply = () => {
		if (handleSubmitReplyState) {
			handleSubmitReplyState();
		}
	};

	return (
		<div className="mb-5">
			{isLoading ? (
				<CenteredContainer>
					<Spinner />
				</CenteredContainer>
			) : (
				<form>
					<div className="mb-4">
						<a className="btn btn-primary btn-lg mr-3" onClick={submitReply} disabled={isLoading}>
							<span>Submit</span>
						</a>
						<a className="btn btn-primary btn-lg" onClick={cancelReply}>
							<span>Cancel</span>
						</a>
					</div>
					<div className="form-group mt-3">
						<textarea
							onChange={handleChange}
							value={formData.Body}
							id="txtReplyMessage"
							name="Body"
							className="form-control form-control-lg"
							placeholder="Message"
							rows="5"
							required
							disabled={isLoading}
						/>
						{/* <TinyMCEEditor id="txtReplyMessage" initialValue="" onEditorChange={handleEditorChange} /> */}
					</div>
				</form>
			)}
		</div>
	);
};

export default TicketReply;
