import { Fragment, useEffect, useState } from 'react';
import './AppSidePanel.css';

const AppSidePanel = ({ children, show, onClose }) => {
	const [ showPanelClass, setShowPanelClass ] = useState('');

	useEffect(() => {
		if (show) {
			setTimeout(() => {
				setShowPanelClass('active');
			}, 300);
		} else {
			setShowPanelClass('');
		}
	}, []);

	const closeSidebar = () => {
		if (onClose) {
			setShowPanelClass('');

			setTimeout(() => {
				onClose();
			}, 500);
		}
	};

	const handleCloseClick = () => {
		closeSidebar();
	};

	return (
		<Fragment>
			{show && (
				<Fragment>
					<div className={`app-sidebar-modal ${showPanelClass}`} />
					<div className={`app-sidebar ${showPanelClass}`}>
						<div className="app-sidebar-actions">
							<a onClick={handleCloseClick} className="btn-link app-link">
								<i className="fas fa-times" />
							</a>
						</div>
						<hr />

						<div className="app-sidebar-content">{children}</div>
					</div>
				</Fragment>
			)}
		</Fragment>
	);
};

export default AppSidePanel;
